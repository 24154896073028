<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <img class="logo_img mb-16" src="@/assets/image/medal_logo_green.png" />
        <div class="fm-12 f-g40 mb-16">매달 파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14">1.개인 정보 입력</li>
            <li class="ml-16 fm-14">2.분야 입력</li>
            <li class="ml-16 fm-14">3.가입동의</li>
            <li class="ml-16 fm-14">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div class="contentBox">
        <div class="flex-justify-center w-100">
          <span class="apply-title"
            >매달은 함께하는 파트너의 지속적인 성장을 위한 서비스를
            제공합니다.</span
          >
        </div>
        <div class="bigLogo">
          <img class="apply-logo" src="@/assets/image/calendar2.png" />
        </div>
        <p class="apply-logo-title">파트너 가입 신청 안내</p>
        <p class="apply-logo-sub-title">
          아래 사항을 확인후 다음을 눌러 가입신청을 진행해주세요.
        </p>
        <div class="join-condition">
          <span class="apply-join-title">파트너 가입 안내</span>
          <ul class="p-16 mt-8">
            <li class="fr-12 ml-16">
              파트너 회원은 가입자의 개인정보(이름, 생년월일, 금융계좌정보,
              휴대폰번호 등) 인증 후 가입신청이 가능합니다.
            </li>
            <li class="fr-12 ml-16" style="margin-top: 2px">
              사업자등록이 되어있는 사업자 회원의 경우 대표자 또는 담당자의 개인
              정보로 가입 후 사업자정보 등록이 가능합니다.
            </li>
            <li class="fr-12 ml-16" style="margin-top: 2px">
              사업자등록이 되어있지 않다면 개인 회원으로 활동이 가능하며
              세금계산서가 발행되지 않습니다.
            </li>
            <li class="fr-12 ml-16" style="margin-top: 2px">
              가입 이후 사업자등록을 하셨다면 '내 정보 > 사업자 변경' 메뉴를
              통해 사업자로 전환할 수 있습니다.
            </li>
            <li class="fr-12 ml-16" style="margin-top: 2px">
              아이디로 설정한 이메일로 가입승인 내역 및 소식을 받아보실수
              있습니다.
            </li>
          </ul>
        </div>
        <div class="pt-16 essential-document">
          <span class="apply-join-title">필수서류</span>
          <p class="apply-join-sub-title">
            파트너 회원의 전문성을 검토하기 위해 가입승인 과정에서 자격증명
            서류를 확인하고 있습니다.<br />미등록 시 가입승인이 안될 수 있으니
            아래 자격증명 서류를 준비해주세요.
          </p>
          <div class="essential-document-wrap mt-8">
            <div class="fr-12 p-16">자격 증명 서류</div>
            <ul class="p-16">
              <li class="fr-12">신청 분야와 관련된 자격증 및 학력증명 서류</li>
              <li class="fr-12">
                신청 분야의 활동과 경력을 증명할만한 온라인 사이트 URL 주소
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="next mt-32 mb-80">
      <button-common :size="SIZE_S" :clr="CLR_B" @click="goAuth()"
        >다음</button-common
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Apply",
  components: {},
  data() {
    return {};
  },
  methods: {
    goAuth() {
      this.$router.push({
        path: `/sign/business`,
        meta: { id: "0-1-10" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";
.apply-logo {
  width: 100px;
  height: 98px;
  margin-left: auto;
  margin-right: auto;
}

.apply-title {
  font-size: 16px;
  color: #515466;
  font-weight: 700;
}

.apply-logo-title {
  font-size: 14px;
  color: #515466 !important;
  font-weight: 700;
  margin-bottom: 4px;
}

.apply-logo-sub-title {
  font-size: 12px;
  color: #828699 !important;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--gray20);
}

.apply-join-title {
  font-size: 12px;
  color: #515466 !important;
  font-weight: 500;
}

.apply-join-sub-title {
  font-size: 12px;
  color: #828699 !important;
  font-weight: 500;
  padding-bottom: 12px;
}
</style>
